@import './../../scss/mixins';

.onboarding {
  height: 100vh;

  @include tab-xs {
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    min-height: 100vh;
  }

  .otp-field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -5px;

    .otp-field__input {
      padding: 13.5px 14px;
      width: 44px;
      height: 44px;
      margin: 0 5px;
      box-sizing: border-box;
      border: 1px solid #cfd0d1;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }

    .wrong_otp {
      border: 1px solid #f84444;
    }
  }

  .onboarding-banner {
    width: calc(100% - 480px);

    @include desktop-sm {
      width: calc(100% - 450px);
    }

    @include tab-sm {
      width: calc(100% - 400px);
    }

    @include tab-xs {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: table-cell;
      object-position: top right;
    }
    &.hide-banner{
      @include mobile{
        display: none;
      }
    }
  }

  .onboarding-content {
    overflow-y: auto;
    width: 480px;
    padding: 40px 70px;

    @include desktop-sm {
      width: 450px;
      padding: 32px 50px;
    }

    @include tab-sm {
      width: 400px;
      padding: 32px 40px;
    }

    @include tab-xs {
      width: 100%;
    }

    @include mobile {
      padding: 32px 20px;
    }

    .login {
      position: relative;
      display: inline-block;
      width: 100%;

      .close-btn-icon {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(15px, -50%);
      }
    }

    hr {
      margin: 24px 0 30px;
      border-top: 1px solid #eaeaea;

      @include mobile {
        margin: 15px 0 20px;
      }
    }

    .cc-prop {
      p {
        position: relative;
        font-size: 14px;
        color: #000000;

        &::after {
          position: absolute;
          content: '';
          right: -11px;
          top: 3.5px;
          height: 14px;
          width: 1px;
          background-color: #bcbcbc;
          color: #000000;
        }
      }
    }

    .cc-prefix {
      margin-right: 0px;

      .black {
        padding-right: 10px;
        border-right: 1px solid #d9d9d9;
        line-height: 1;
      }
    }
  } 

  .change-login-no-content {
    overflow-y: auto;
    width: 535px;
    padding: 0 30px 20px 30px;

    .bottom-margin{
      margin-top: 240px;
    }


    @include desktop-sm {
      width: 450px;
      padding: 32px 50px;
    }

    @include tab-sm {
      width: 400px;
      padding: 32px 40px;
    }

    @include tab-xs {
      width: 100%;
    }

    @include mobile {
      padding: 32px 20px;
    }

    .login {
      position: relative;
      display: inline-block;
      width: 100%;

      .close-btn-icon {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(15px, -50%);
      }
    }

    hr {
      margin: 24px 0 30px;
      border-top: 1px solid #eaeaea;

      @include mobile {
        margin: 15px 0 20px;
      }
    }

    .cc-prop {
      p {
        position: relative;
        font-size: 14px;
        color: #000000;

        &::after {
          position: absolute;
          content: '';
          right: -11px;
          top: 3.5px;
          height: 14px;
          width: 1px;
          background-color: #bcbcbc;
          color: #000000;
        }
      }
    }

    .cc-prefix {
      margin-right: 0px;

      .black {
        padding-right: 10px;
        border-right: 1px solid #d9d9d9;
        line-height: 1;
      }
    }
  }

  .change-login-no-banner {
    width: calc(100% - 450px);

    @include desktop-sm {
      width: calc(100% - 400px);
    }

    @include tab-sm {
      width: calc(100% - 350px);
    }

    @include tab-xs {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: table-cell;
      object-position: top right;
      @include mobile{
        display: none;
      }
    }
  
    
    
  }
}
